<template>
  <div>
    <div class="d-flex justify-content-center align-items-center mx-auto" v-if="loading">
      <ChLoading size="md" class="spinner" />
    </div>
    <div v-else>
      <div class="text-center creation-text" v-if="!userHasCharacters" :class="{ hidden: isCreationsVisible }">
        Create a character in 48 hours to win
      </div>
      <div v-if="displayCreationButton" class="my-2 position-relative">
        <div
          class="position-absolute d-flex align-items-center"
          :class="isCreationsVisible ? 'visible-timer-badge' : 'timer-badge'"
        >
        <vue-countdown v-slot="{ days, hours, minutes, seconds }" :time="challengeEndTime" class="d-flex align-items-center">
          <ion-icon :class="['small-icon mx-1', { 'visible-small-icon': isCreationsVisible }]" :icon="timeOutline" />
          <span v-if="days" :class="{ 'visible-samll-timer': isCreationsVisible }" class="small-timer text-white mr-1" >{{ days }}d</span>
          <span v-if="hours" :class="{ 'visible-samll-timer': isCreationsVisible }" class="small-timer text-white">{{ hours }}h</span>
          <span v-if="!days" :class="{ 'visible-samll-timer': isCreationsVisible }" class="small-timer text-white mx-1">{{ minutes }}m</span>
          <span v-if="!days && hours" :class="{ 'visible-samll-timer': isCreationsVisible }" class="small-timer text-white">{{ seconds }}s</span>
        </vue-countdown>
        </div>
        <ion-button class="big-btn" :class="{ 'visible-big-btn': isCreationsVisible }" @click="characterCreation">
          <div class="content d-flex align-items-center justify-content-center">
            <img :class="isCreationsVisible ? '' : 'mr-2'" src="/create_char_onboarding.png" height="30" width="30" />
            <span :class="{ hidden: isCreationsVisible }">Create Character</span>
          </div>
        </ion-button>
      </div>
      <div
        v-else-if="!displayCreationButton && !userHasCharacters"
        class="d-flex align-items-center p-2 timer-border my-2 w-75 mx-auto text-center justify-content-center"
      >
        <ion-icon class="icon mx-2" :icon="timeOutline" />
        <vue-countdown v-slot="{ minutes, seconds, totalHours }" :time="challengeEndTime">
          <span v-if="totalHours" class="timer-set">{{ totalHours }}<span class="timer-type">h</span> </span
          ><span class="timer-set">{{ minutes }}<span class="timer-type">m</span></span
          ><span class="timer-set">{{ seconds }}<span class="timer-type">s</span> </span>
        </vue-countdown>
      </div>

      <CurrencyBar
        v-if="userHasCharacters && isChallengeModal"
        :setNoPadding="true"
        :coins="totalCurrency"
        :gems="get(usersCurrency, 'gems')"
        :earned="rewardCurrency"
      />
      <div class="rewards" :style="isCreationsVisible ? 'font-size: 16px !important' : ''">Rewards</div>

      <div
        class="d-flex align-items-center"
        :class="{ 'justify-content-center': rewards.length <= 2, 'not-visible-rewards': isCreationsVisible }"
        style="gap: 5px"
      >
        <div v-for="(reward, index) in rewards">
          <div
            class="rewards-card position-relative py-3 px-2 d-flex justify-content-center flex-column"
            :class="{
              'new-rewards-card': displayWhiteBg,
              'small-reward-card': displaySmallCard,
              'not-visible-small-card': isCreationsVisible,
            }"
          >
            <div
              class="position-relative d-flex align-items-center justify-content-center mx-auto"
              :class="isCreationsVisible ? '' : 'mb-1'"
            >
              <img
                class="img"
                :src="reward.img"
                :class="{
                  'side-img': displayWhiteBg,
                  'small-img': displaySmallCard,
                  'not-visible-small-img': isCreationsVisible,
                }"
              />
            </div>
            <div class="mt-auto" style="color: #431c89" :class="{ hidden: isCreationsVisible }">
              <div class="align-items-center d-flex text-center justify-content-center position-relative">
                <div class="truncate" :style="displayWhiteBg ? 'color: #7050b7 !important' : ''">
                  {{ reward.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { timeOutline } from 'ionicons/icons';
import { getUserChallengeJoiningEligibilityRewards } from '../actions/onboarding';
import { authStore } from '../pinia-store/auth';
import CurrencyBar from '@/shared/pages/store/CurrencyBar.vue';

const props = defineProps({
  challengeEndTime: {
    type: Number,
    default: 0,
  },
  displayWhiteBg: {
    type: Boolean,
    default: false,
  },
  displayCreationButton: {
    type: Boolean,
    default: false,
  },
  isCreations: {
    type: Boolean,
    default: false,
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
  displayBgForChallenge: {
    type: Boolean,
    default: false,
  },
  displaySmallCard: {
    type: Boolean,
    default: false,
  },
  isChallengeModal: {
    type: Boolean,
    default: false,
  },
  isRewardClaim: {
    type: Boolean,
    default: false,
  },
});

const isVisible = toRef(props, 'isVisible');
const isRewardClaim = toRef(props, 'isRewardClaim');
const isChallengeModal = toRef(props, 'isChallengeModal');
const isCreations = toRef(props, 'isCreations');
const emits = defineEmits(['items', 'load']);
const loading = ref(true);

const { challengetext } = useCharPageColors();
const { usersCurrency, userHasCharacters, getUsersCurrency } = authStore();
const rewardItems: any = ref({});
const rewards = computed(() => {
  return [
    {
      name: get(rewardItems.value, 'profile_ring_awarded.title'),
      img: get(rewardItems.value, 'profile_ring_awarded.image'),
      key: 'profile_ring',
    },

    {
      name: get(rewardItems.value, 'points_awarded'),
      img: '/onboarding_hubucks_pack.png',
    },
  ];
});
watch(loading, () => {
  emits('load', loading.value);
});
const totalCurrency = computed(() => {
  const coins = get(usersCurrency.value, 'coins', 0);
  const pointsAwarded = get(rewardItems.value, 'points_awarded', 0);
  return isRewardClaim.value ? coins : coins - pointsAwarded;
});

const rewardCurrency = computed(() => {
  if (isRewardClaim.value) {
    return get(rewardItems.value, 'points_awarded');
  }
});
const fetchEligibleRewards = async () => {
  try {
    const resp = await getUserChallengeJoiningEligibilityRewards();

    rewardItems.value = resp;
    loading.value = false;
    emits('items', rewardItems.value);
  } catch (e) {
    loading.value = false;
  }
};

const isCreationsVisible = computed(() => {
  return !isVisible.value && isCreations.value;
});
const characterCreation = () => {
  const router = useRouter();
  router.push({ name: 'character-creator' });
};

onMounted(async () => {
  if (isChallengeModal.value) await getUsersCurrency();
  fetchEligibleRewards();
});
</script>
<style lang="sass" scoped>
.creation-text
  color: v-bind(challengetext)

.not-visible-rewards
  flex-direction: column
  justify-content: unset !important
  align-items: unset !important
.visible-big-btn
  width: 80% !important
.hidden
  display: none
.visible-samll-timer
  font-size: 11px !important
.reward-claim
  display: flex
  align-items: start
  font-size: 18px
  margin-bottom: 10px
.small-timer
 font-size: 14px
 font-weight: bolder
 padding-top: 1px
.timer-badge
  background: #42D6DA
  border: 2px solid #214163
  border-radius: 20px
  z-index: 2
  transform: translateX(-50%)
  left: 50%
  top: -10px
  padding: 0px 10px 2px 2px
  height: 25px
.visible-timer-badge
  background: #42D6DA
  border: 2px solid #214163
  border-radius: 20px
  z-index: 2
  top: -10px
  height: 25px
  padding: 5px
.big-btn
  width: 100%
  height: 54px
  max-height: unset !important
  border: 2px solid #244163
  border-radius: 16px
  align-items: center
  background: rgb(247,6,207)
  background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 20%, rgba(95, 169, 238, 0) 100%), #A83CFF
  --background: transparent
  --padding-start: 0
  --padding-end: 0
  --padding-top: 0
  --padding-bottom: 0
  overflow: hidden
  &:active, &:focus
    opacity: 0.7
  .content
    user-select: none !important
    border-top: 0 !important
    border-left: 0 !important
    border-right: 0 !important
    border-radius: 16px
    margin-top: -6px
    margin-left: 0px
    width: calc(100% + 10px)
    max-width: unset !important
    min-width: unset !important
    height: calc(100% - 3px)
    border: 2px solid rgba(250, 166, 203, 1)
    font-weight: bold
    font-size: 24px
    font-family: "Roboto Slab", serif
    padding: 5px 0 0 0 !important
    background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 0%, rgba(95, 169, 238, 0) 100%), #A83CFF

.timer-set
  color: #431C89
  font-size: 25px
  font-weight: bolder
.dark .timer-set
  color: #fff
.dark .timer-type
  color: #fff
.dark .icon
  color: #fff
.timer-type
  font-size: 24px
  color: #9874DB
  margin-right: 0.5rem
.icon
  color: #9874DB
  font-size: 30px
  font-weight: bolder
.small-icon
  color: #fff
  font-size: 16px
  font-weight: bolder
.visible-small-icon
  font-size: 11px
.timer-border
  border: 2px solid #AF97DB
  border-radius: 30px
  height: 43px
.dark .timer-border
  border: 2px solid white !important
.rewards
  color: v-bind(challengetext)
  font-weight: bolder
  font-size: 18px
  align-items: start
  display: flex
.new-rewards-card
  border: 2px solid #F4D1FB !important
  background: white !important
.small-reward-card
  max-width: 115px !important
  max-height: 140px !important
  min-height: 140px !important
.not-visible-small-card
  max-width: 65px !important
  max-height: 65px !important
  padding: 0.25rem !important
  min-height: 65px !important

.rewards-card
  height: 100%
  width: 100%
  max-width: 126px
  max-height: 160px
  min-height: 160px
  border-radius: 17px
  position: relative
  background: linear-gradient(0deg, rgba(255, 164, 240, 0) 0%, rgba(255, 200, 246, 0.5) 100%), #EADBFF
.img
  border-radius: 16px
  background-color: rgba(255, 255, 255, 0.5)
  margin: auto 0
  border: 2px solid white
  padding: 0.3rem
  min-height: 99px
  max-height: 99px
  width: 110px
  object-fit: contain !important
  height: 100% !important
  aspect-ratio: 1 !important
.side-img
  border: 2px solid #F4D1FB
.small-img
  min-height: 80px !important
  max-height: 80px !important
.not-visible-small-img
  min-height: 55px !important
  width: 55px !important
  max-height: 55px !important
</style>
